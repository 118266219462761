

























































































































































































































































import KioskError from './KioskErrorModal'
import Modal from "@/components/Modal.vue";
import { Device as DeviceService, Customer } from "@/services/SOLO";
import { Select, Option } from "element-ui";
import { ValidationObserver, configure, extend, validate } from "vee-validate";
import { Facility,  DeviceSettings } from "@/models";
import {
  Component,
  ProvideReactive,
  Vue,
  Prop,
  Watch,
  Emit,
} from "vue-property-decorator";
import { translations, notificationAlerts, eventHandler } from "@/mixins";
import Multiselect from 'vue-multiselect';
import KioskErrorModal from './KioskErrorModal.vue';



@Component({
  components: {
    LoadingPanel: () => import("@/components/LoadingPanel.vue"),
    [Select.name]: Select,
    [Option.name]: Option,
    Multiselect,
    },
    mixins: [eventHandler, translations, notificationAlerts],
})
export default class FacilitiesFormModal extends Vue {
  open: boolean = false;
  parent: any;
  searchText: any = ''
  searchLocation: any = ''
  saving: boolean = false;
  $notify: any;
  customerMeta: any = {}
  loading: any = false
  searching: any = false
  $refs!: {
    formValidator: any;
  };
  model: DeviceSettings = {
    id: "",
    label: "",
    "location-id": "",
    "customer-id": "",
    "payment-gateway": "",
    uuid: "",
    "is-settings-password-protected": false,
    "ip-address": "",
    "terminal-id": "",
    type: 'ipad',
    port: '',
    'printer-settings': {
      'printer-manufacturer': '',
      'receipt-mode': '',
      'ip-address': '',
      'printer-name': '',
    },
    'payment-settings': {
      'terminal-poiid': '',
      'key-version': '',
      'key-identification': '',
      'key-passphrase': '',
    }
  };
  devices: any = [
    {text: 'Counter-Top (iPad)', value: 'ipad'},
    {text: 'Tower (Windows)', value: 'windows'},
  ]
  manufacturers: any = [
    {text: 'SUNMI', value: 'sunmi'},
    {text: 'Epson', value: 'epson'},
  ]
  modes: any = [
    {text: 'SMS', value: 'sms'},
    {text: 'QR', value: 'qr'},
    {text: 'PRINTER', value: 'printer'},

  ]

  terminal: any = [
    {text: 'Intersoft', value: 'intersoft'},
    {text: 'Geidea PAX 1920 Pro', value: 'geidea-pax'},
    {text: 'Geidea Verifone VX690', value: 'geidea-verifone'},
    {text: 'Foodics Pay', value: 'foodics-pay'},
    {text: 'Paymob', value: 'paymob'},
  ]

  terminal1: any = [
    {text: 'Ingenico', value: 'ingenico'},
    {text: 'Geidea PAX 1920 Pro', value: 'geidea-pax'},
    {text: 'Geidea Verifone VX690', value: 'geidea-verifone'},
    {text: 'Adyen', value: 'adyen'},
    {text: 'Foodics Pay', value: 'foodics-pay'},
    {text: 'Paymob', value: 'paymob'},

  ]
  options: any = [];
  customers: any = []
  confirm1!: Function
  translate!: Function;
  systemErrorNotification!: Function;
  successNotification!: Function;
  POSvalue: Boolean = true

  @Prop() locations!: any;

  @Watch("open", {immediate: true, deep: true})
  onOpenChanged(newVal: any) {
    console.log("check locationsss", this.locations);
    if (!newVal) {
      this.closeMe();
      this.resetModel();
    } else {
      if(this.locations.length > 0) {
        let loc = this.locations.find((loc: any) => Number(loc?.id) === Number(this.model['location-id']));
        this.model['location-id'] = loc['id'];
      }
    }
  }

  @Watch("model", {immediate: true, deep: true})
  checkPOSValue(newVal: any) {
    if (newVal) {

      this.filterCustomer(this.model['customer-id']);

      console.log("check location", newVal['location-id']);

      const found = this.locations
            .find((loc: any) => Number(loc.id) === Number(newVal['location-id']));

      console.log("check found", found);


      if (newVal['payment-gateway'] == 'geidea-pax') {
        this.model.port = 6100;
      } 

      if (newVal['payment-gateway'] == 'geidea-verifone') {
        this.model.port = 80;
      } 

      if(found?.attributes.pos === 'foodics-f5' || found?.attributes.pos === 'foodics' || found?.attributes.pos === 'ocost' || found?.attributes.pos === 'sdm' || found?.attributes.pos === 'revel' || found?.attributes.pos === 'posist') {
        this.POSvalue = false;
      } else {
        this.POSvalue = true;
      }
    }
  }
  mounted() {
    this.getCustomers(1);
  }


  async searchCustomer(name: any) {
    this.customers = [];
    this.searching = true
    await Customer.searchInKiosk(name).then((response: any) => {
      // this.customers = response.data.data;
      this.customers = response?.data?.data;
      console.log("search cus", this.customers); 
      this.searching = false
    });

  }

  async searchLocationFunc(name: any) {
    let lang = 'en-us';
    this.locations = [];
    this.loading = true
    await Customer.searchLocInKiosk(name,lang).then((response: any) => {
      this.locations = response?.data?.data;
      console.log("search loc", this.locations); 
      this.loading = false
    });

  }

  async filterCustomer(name: any) {
    this.loading = true
    await Customer.getCustomerName(name).then((response: any) => {
      console.log("search cus", response.data.data); 
      this.customers = response.data.data
      this.loading = false
    });

  }

  async getCustomers(page: 1) {
    this.loading = true
    await Customer.all(page)
      .then((response: any) => {
        this.customers = response.data.data
        this.customerMeta = response.data.meta.pagination
        console.log("get customers", response.data.data);
        this.loading = false
      })
  }
  resetModel() {
    this.model = {
     id: "",
     label: "",
    "location-id": "",
    "customer-id": "",
    "payment-gateway": "",
    uuid: "",
    "ip-address": "",
    "terminal-id": "",
    type: 'ipad',
    "is-settings-password-protected": false,
    port: '',
    'printer-settings': {
      'printer-manufacturer': '',
      'receipt-mode': '',
      'ip-address': '',
      'printer-name': '',
    },
    'payment-settings': {
      // 'business-reference-id':'',
      'terminal-poiid': '',
      'key-version': '',
      'key-identification': '',
      'key-passphrase': '',
    }
    }
  }


  closeMe() {
    this.open = false;
    this.$emit("close");
  }

  deleteMsgBoxText(e: any) {
    return this.translate(e) + 'You need a kiosk license to add a kiosk device, go to Account Page please click (OK).';
  }

  async submit(e: any) {

    const isValid = await this.$refs.formValidator.validate();
    if (!isValid || this.saving) {
      return;
    }

    /* @ts-ignore */
    this.saving = true;
    try {
      if (this.model.id) {
        let response = await DeviceService.update(this.model.id, this.model);
      } else {
        let response = await DeviceService.create(this.model);
      }

      this.successNotification();
      this.saving = false;
      this.closeMe();
      this.$emit("successfulFormSubmit");
      this.resetModel();
    } catch (error) {
      /* @ts-ignore */
      console.log("check error kiosk", error.response.data.error[0].detail);
      // this.$emit("modal:openKioskError", true);
      /* @ts-ignore */
     const re = await this.confirm1(this.$bvModal, this.deleteMsgBoxText(error.response.data.error[0].detail))
      if(re) {
        this.$router.push('/account');
      }
      this.saving = false;

    }
  }
}
